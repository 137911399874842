div.labeling-container {
  margin-bottom: 1em;
  border: 1px solid #ccc;
  padding: 0.5em;
}

div.labeling-select-button {
  cursor: pointer;
  font-weight: bold;
  padding: 0 0.5em;
  border: 1px solid #000;
  border-radius: 3px;
  float: right;
}

ul.labeling-list {
  padding-left: 1em;
  margin-bottom: 0;
  list-style-type: square;
}
