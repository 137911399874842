$lli-bc: #DDD;
$highlight-background-color: #DDD;

div.label-list-item {
  padding: 0.5em 0;

  &:first-of-type {
    border-top: 1px solid $lli-bc;
  }

  &:last-of-type {
    border-bottom: 1px solid $lli-bc;
  }
}

div.lli-label-flex {
  display: flex;
  justify-content: space-between;
}

div.lli-label {
  width: calc(100% - 3em);
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background-color: $highlight-background-color;
  }
}

div.lli-remove {
  display: inline-block;
  width: 3em;
  border-left: 1px solid $lli-bc;
  margin: auto 0;
  text-align: center;
  cursor: pointer;
  &:hover {
    font-weight: bold;
  }
}

p.lli-label-name {
  padding-left: 0.5em;
  margin: 0;
}

div.lli-add-label {
  width: 100%;
  font-size: 125%;
  text-align: center;
  color: #999;
  text-shadow: 1px 1px lighten(#999, 10%);
  cursor: pointer;
}

div.lli-selectors {
  display: flex;
  width: 100%;
}

input.lli-filter {
  flex-grow: 10;
  min-width: 5em;
}

div.lli-clear-filter {
  width: 1em;
  margin: auto 0 auto -1.7em;
  display: inline;
  padding: 0 0.5em;
  background-color: white;
  z-index: 1;
  cursor: pointer;
  &:hover {
    font-weight: bold;
  }
}

input.lli-percentage {
  width: 5em;
  margin-left: 1em;
}

div.lli-confirm {
  display: inline;
  align-self: flex-end;
  padding: 0.2em 1em;
  margin-left: 0.5em;
  cursor: pointer;
  border-radius: 3px;
}

div.lli-label-container {
  width: 100%;
  overflow-y: auto;
  border: 1px solid #ccc;
  margin-top: 0.25em;
  border-radius: 3px;
  background-color: white;
}

div.lli-label-container-selected {
  width: 100%;
  overflow-y: auto;
  border: 0px;
  margin-top: 0.25em;
  border-radius: 3px;
}

div.lli-label-button {
  padding: 0.25em 0.5em;
  cursor: pointer;
  &:hover {
    background-color: $highlight-background-color;
  }
}

div.lli-label-button-selected {
  background-color: #DEEBFF;
}

button.lli-make-100 {
  background-color: cornflowerblue;
  color: white;
  font-weight: bold;
  font-size: small;
  margin-bottom: 0.5em;
  border: 0;
  border-radius: 0 0 1em 1em;
}

h1.ee-heading {
  font-size: 1em;
  font-weight: bold;
  border-bottom: 1px solid black;
  margin: 1em 0 0;
}

div.ee-container {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 0.5em 0 0;
}

div.ee-left {
  width: calc(100% - 4em);
}

div.ee-right {
  width: 3em;
  text-align: center;
  margin: auto 0;
  border-left: 1px solid $lli-bc;
  cursor: pointer;
  &:hover {
    font-weight: bold;
  }
}
