@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,800;1,200;1,300;1,400;1,600;1,800&display=swap');

$color-primary: #6eb882;

h3 {
  font-size: 18px;
}

h2 {
  font-size: 19px;
}

h1 {
  font-size: 23px;
}

.backBtn {
  position: fixed;
  width: 33%;
  height: 40px;
  right: 27px;
  bottom: 31px;
  font-size: 18px;
}

.nextBtn {
  width: 450px;
  margin: 5px;
  margin-left: 18px;
}

.imageContainer {
  width: 99vh;
  height: 74vh;
  position:relative;
  float:left;
  display:block;
  box-sizing: border-box;
  line-height: 74vh;
  text-align: center;
  font-size: 14px;
  background: white url('./assets/svgs/Eclipse-1s-200px.svg') center no-repeat;

}

.imageContainerDiv {
  overflow:hidden;
  max-width: 99vh;
  //width: 86vw;
  height: 74vh;
  position:relative;
  display:inline-block;
}

.photo {
  //width: 100%;
  //height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  //background-size: cover;
  transition: transform 0.05s ease-out;
  overflow:hidden;
}

body {
  overflow:hidden;
  font-family: 'Nunito';
  background-color: #fafafa;

}

.flr {
  float: right;
}

.ctnButton {
  position: fixed;
  width: 16%;
  height: 50px;
  right: 25px;
  bottom: 50px;
  font-size: 18px;
  border-color: grey;
}

.nctnButton {
  position: fixed;
  width: 16%;
  height: 50px;
  right: calc( 27px + 16% );
  bottom: 50px;
  font-size: 18px;
  border-color: grey;
}

.labelsContainerWindow {
  width: 33%;
  height: 74vh;
  position: absolute;
  right: 25px;
  background-color: #fafafa;
}

.labelsContainerSeperate {
  padding: 5px;
  //width: 33%;
  //height: 18vh;
  position: relative;
}

.itemLabelsBoxes {
  overflow-x: hidden;
  overflow-y: scroll;
  display:flex;
  flex-wrap: wrap;
  height: 73vh;
}

.itemLabelBox {
  width: 48%;
  height: 40px;
  text-align: center;
  border: 1px solid black;
  border-radius: 5px;
  padding: 2px;
  padding-top: 8px;
  margin:2px;
}

.itemLabelBox:hover {
  background-color: #fafafa;
  border: 1px solid grey;

}

.itemLabelBoxFruit {
  background-color: rgba(255, 159, 86, 0.5);
}

.itemLabelBoxVegetable {
  background-color: rgba(38, 255, 114, 0.5);
}

.itemLabelBoxStarch {
  background-color: rgba(181, 123, 23, 0.5);
}

.itemLabelBoxProtein {
  background-color: rgba(255, 112, 121, 0.5);
}

.itemLabelBoxDairy {
  background-color: rgba(255, 247, 247, 0.5);
}

.itemLabelBoxOther {
  background-color: rgba(221, 221, 221, 0.5);
}

.itemLabelBoxDessert {
  background-color: rgba(255, 246, 206, 0.5);
}

.itemLabelBoxNon-food {
  background-color: rgba(209, 209, 209, 0.5);
}


.typeLabelsBoxes {
  overflow-x: hidden;
  overflow-y: scroll;
  display:flex;
  flex-wrap: wrap;
}

.typeLabelBox {
  width: 48%;
  height: 40px;
  text-align: center;
  border: 1px solid black;
  border-radius: 5px;
  background-color: #cccccc;
  padding: 2px;
  padding-top: 8px;
  margin:2px;
}


.typeLabelBox:hover {
  background-color: #fafafa;
  border: 1px solid grey;

}

.cookedLabelBox {
  width: 41%;
  margin: 0 1%;
  text-align: center;
  padding: 4px;
  background-color: #dddddd;
  border: 1px solid #bbbbbb;
  border-radius: 5px;
  font-size: 18px;
  display:inline-block;
}

.cookedLabelBox:hover {
  background-color: #ffffff;
  border: 1px solid #cccccc;
}

.errBtn {
  margin:0 10% 5px 10%;
  width: 80%;
  opacity: 0.8
}

.greyOut > * {
  opacity: 0.4;
}

.backToNextImage {
  position: fixed;
  width: 33%;
  height: 40px;
  right: 27px;
  bottom: 75px;
  font-size: 18px;
}

.addAndNextImage {
  @extend .backToNextImage;
  bottom: 135px;
}

.noisyImage {
  position: fixed;
  width: 33%;
  height: 40px;
  right: 27px;
  bottom: 135px;
  font-size: 18px;
  background-color: #a00;
}

.missingLabel {
  position: fixed;
  width: 33%;
  height: 40px;
  right: 27px;
  bottom: 195px;
  font-size: 18px;
  background-color: #c70;
}

.segcheck {
  height: 40px;
  border-color: #000;
  border-width: 2px;
  width: 40%;
  margin-top: 1em;
  margin-right: 5%;

  &.approveImage {
    background-color: #0a0;
  }
  &.rejectImage {
    background-color: #a00;
  }
  &.ignoreImage {
    background-color: #a70;
  }
  &.skipImage {
    background-color: #07a;
  }
  &.missingLabelChecked {
    background-color: #a07;
  }
}

.commentDiv {
  width: 85%;
}

.commentTA {
  width: 100%;
  border: '1px solid #777';
  border-radius: '3px';
  width: '100%';
  min-height: '4em';
  padding: '1em';
}

.commentPreset {
  font-size: 70%;
  margin-right: 0.5em;
}

.batchButton {
  height: 250px;
  width: 250px;
  border-radius: 5px;
  border: 1px solid black;
  margin: 25px;
}

.predefinedButtons {
  //border-radius: 100px;
  position: absolute;
  top: 115px;
  width: 10%;
  background-color: white;
}

.dontKnow {
  right: 1.5%;
}

.error {
  right: calc( 11.5% + 25px)
}

.emptyBin {
  right: calc( 21.5% + 50px)
}

.favorite {
  border-radius: 100px;
  position: absolute;
  right: 45px;
  top: 107px;
}

.review {
  border-radius: 100px;
  position: absolute;
  right: 81px;
  top: 107px;
}

.editLabels {
  width: 150px;
  float: right;
  top: -15px;
  position: relative;
}

.addLabel {
  width: 35px;
  float: right;
  top: -15px;
  position: relative;
  margin-right: 5px;
}

.selectClassItem {
  display: inline-block;
  width: 50%;
}

.selectClassType {
  display: inline-block;
  width: 25%;
}

.selectClassCooked {
  display: inline-block;
  width: 20%;
}

.prevLabels {
  margin-top: 20px;
}

.weightAllocationPercentage {
  width: 65px;
  float: right;
  margin-top: -8px;
  margin-left: 5px;
}

// .strongBorder {
//   //border: 3px solid black;
// }

.stageButton {
  float: left;
  opacity: 1;
  border-radius: 50%;
}

.deploymentBtn {
  display: block;
  background: none;
  color: #20a8d8;
  border-radius: 0px;
  border: none;
}

.deploymentBtn:hover {
  background: none;
  color: #0f4b60;
  font-weight: bold;
}

.deploymentBtn:active {
  background: none;
  color: #85D0EA;
}

.top-right {
  position: absolute;
  top: 8px;
  right: 16px;
  color: white;
}

.percentageInput {
  width: 48px;
  margin:10px;
  margin-right: 20px;
  display:inline-block;
}

/* The switch - the box around the slider */
.switch {
  margin:5px;
  position: relative;
  display: inline-block;
  width: 45px;
  height: 25px;
  top: 12px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 1px;
  right: 0;
  bottom: 1px;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input + .slider{
  border: 1px solid #bbb;
}

input:checked + .slider {
  background-color: #4dbd74 !important;
  border: 1px solid #3a9d5d;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.css-10nd86i {
  display: inline-block;
  min-width: 230px;
}

.labelHeader {
  display:inline-block;
  width: 55%;
  min-width:230px;
  float: left;
}
.weightHeader {
  display:inline-block;
  width: auto;
  float: left;
  padding-left: 17px;
  padding-right: 17px;
  border-left: 1px solid black;
  border-right: 1px solid black;
}
.avoidableHeader {
  display:inline-block;
  width: 25%;
  float: left;
  padding-left: 15px;
}

#root {
  background-color: #fafafa;
}

/* The spinner */

@keyframes spinner {
  to {transform: rotate(360deg);}
}

.spinner,
.spinner:before {
  z-index: 1000;
  width: 50px;
  height: 50px;
  box-sizing: border-box;
}

.spinner:before {
  content: '';
  display: block;
  border-radius: 50%;
  border: 5px solid white;
  border-top-color: #333;
  animation: spinner .6s linear infinite;
}

.spinner-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
}

/* Animations */

.spinner-add,
.spinner-remove {
  animation-fill-mode: both;
  animation-duration: .4s;
}

.spinner-add {
  animation-name: spinner-add;
}

@keyframes spinner-add {
  from {transform: scale(0);}
  to {transform: scale(1);}
}

.spinner-remove {
  animation-name: spinner-remove;
}

@keyframes spinner-remove {
  to {transform: scale(0);}
}

#overlay {
  z-index: 999;
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}


/**
 * Extracted from: SweetAlert
 * Modified by: Istiak Tridip
 */
.success-checkmark {
  z-index: 998;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  position: absolute;
  top: calc( 73% - 66px );
  left: calc( 81% - 66px );
  background-color: white;
  padding-top: 30px;
  padding-left: 30px;
  border-radius: 25px;
  display:none;

  .check-icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    box-sizing: content-box;
    border: 4px solid #4CAF50;
    position: relative;

    &::before {
      top: 3px;
      left: -2px;
      width: 30px;
      transform-origin: 100% 50%;
      border-radius: 100px 0 0 100px;
    }

    &::after {
      top: 0;
      left: 30px;
      width: 60px;
      transform-origin: 0 50%;
      border-radius: 0 100px 100px 0;
      animation: rotate-circle 4.25s ease-in;
    }

    &::before, &::after {
      content: '';
      height: 100px;
      position: absolute;
      background: #FFFFFF;
      transform: rotate(-45deg);
    }

    .icon-line {
      height: 5px;
      background-color: #4CAF50;
      display: block;
      border-radius: 2px;
      position: absolute;
      z-index: 10;

      &.line-tip {
        top: 46px;
        left: 14px;
        width: 25px;
        transform: rotate(45deg);
        animation: icon-line-tip 0.75s;
      }

      &.line-long {
        top: 38px;
        right: 8px;
        width: 47px;
        transform: rotate(-45deg);
        animation: icon-line-long 0.75s;
      }
    }

    .icon-circle {
      top: -4px;
      left: -4px;
      z-index: 10;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      position: absolute;
      box-sizing: content-box;
      border: 4px solid rgba(76, 175, 80, .5);
    }

    .icon-fix {
      top: 8px;
      width: 5px;
      left: 26px;
      z-index: 1;
      height: 85px;
      position: absolute;
      transform: rotate(-45deg);
      background-color: #FFFFFF;
    }
  }
}

@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}

@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes icon-line-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

.data_name {
  display: inline-block;
  width: 300px;
  margin-right: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.data_address {
  display: inline-block;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.data_additionalInfo {
  display: inline-block;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.data_default {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.data_additionalInfo_property {
  width: 250px;
}

.data_additionalInfo_cuisine_type {
  width: 150px;
}

.data_additionalInfo_pos {
  width: 100px;
}

.data_additionalInfo_num_seats {
  width: 125px;
}

.data_additionalInfo_cuisine_spec {
  width: 150px;
}

.data_additionalInfo_cost_structure {
  width: 150px;
}

.data_additionalInfo_outlet_type {
  width: 125px;
}

.data_additionalInfo_area {
  width: 100px;
}

.data_additionalInfo_deployment {
  width: 225px;
}

.data_labelname {
  width: 500px;
}

.addDataBtn {
  margin-left: 5px;
}

.addDataBtnProperty {
  margin-left: 1%;
  width: 9%;
  float: right;
}

.addDataBtnOutlets {
  margin-top: 10px;
  width: 35%;
}

.addDataBtnDeployment {
  float: right;
  width: 35%;
}

.backToOverview {
  float: left;
  width: 35%;
}

.controlPanel {
  overflow-y: scroll;
  height: 100%;
}

.addRemoveLabel {
  margin: 0 2%;
  width: 9%;
  float: left;
  display: inline-block;
  height :25px;
  padding: 0;
}

.trainingDiv {
  display: flex;
  flex-wrap: wrap;
  flex-basis: auto;
}

.trainingButton {
  padding: calc( 7% + 5px ) 0;
  width: calc( 50% - 20px );
  margin: 10px;
  font-size: 25px;
  font-weight: bold;
  background-color: #d1d1d1;
}

.labelCheckerFilterLabelSelect {
  width: 75%;
  display: inline-block;
  margin-right: 10px;
}

.List {
  border: 1px solid #d9dddd;
}

.ListItemEven,
.ListItemOdd {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ListItemEven {
  background-color: #f8f8f0;

}

.batchOverviewMaster {
  font-size: 0.9em;
  background-color: #fafafa;
  //background-color: grey;
  margin: 10px;
  font-family: 'Nunito';
  height: 100%;
  width: 98%;
}


.batchOverview {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: stretch;
  height: 80.25%;
  width: 100%;
}

.batchOverviewDiv {
  height: calc( 104vh - 125px );
  width: 100%;
  overflow-y: scroll;
  overflow-x: scroll;
  margin-bottom: 10px;
  position: relative;
  top: -40px;

}

.startBatchBox {
  height: 125px;
}

.batchOverviewBox {
  background-color: white;
  width: calc(100% - 30px);
  margin: 0 15px 25px 0;
  padding: 15px;
  box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.05);
  border-radius: 5px;
}

.batchOverviewPanel {
  margin: 5px;
  padding: 10px;
  flex-grow: 1;
  width: 100%;
  height: 100%;
}

.userOverviewBox {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: stretch;
  width: 100%;
}

.flexItemRow {
  width: 100%;
}

.flexItemRowHalf {
  width: 50%;
}

.feedbackBox {
  margin: 15px;
  padding: 10px;
  height: 80px;
}

.availableBatchesBox {
  height: 83.5%;
  width: 100%;
}

.overviewButton {
  border-radius: 50px;
  font-weight: 750;
  float: right;
  border: none;
  width: 150px;
}

.boxHeader {
  font-weight: 750;
  font-size: 1.35em;
}


.showCount {
  position: relative;
  top: -45px;
  float: right;
}

.showCountSwitch {
  top: 15px;
}

.displayNone {
  display: none;
}

.inputWeightSelect {
  width: 25%;
}

.labellerSelect {
  width: 100%;
}

.consensusOptionSelect {
  width: 100%;
}

.labelCheckerTrasheventList {
  height: calc( 94vh - 125px );
  width: 100%;
  overflow-y: scroll;
  overflow-x: scroll;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.labelCheckerTrasheventItem {
  width: 25%;
  padding: 5px;
}

.labelCheckerImageContainer {
  width: 33vh;
  height: 24.7vh;
  background: white url('./assets/svgs/H3e9z0b.gif') center no-repeat;
  background-size: 33vh 24.7vh;
  display: inline-flex;
  margin: 5px;
  border: none;
}

.labelCheckerImageInformation {
  display: block;
  margin-left: 15px
}

.imageOverlay {
  margin: 5px;
  width: 33vh;
  height: 24.7vh;
  position:absolute;
  text-align: center;
  border:none;
  padding: 0;
  color: white;
  font-weight: bold;
}

.labelCheckerModalImageContainer {
  height: 40vh;
  width: 50vh;
}

.errorButtons {
  width: 100%;
  margin: 5px;
}

.selected {
  background-color: #0d5c8c;
  font-weight: bold;
}

.labelCheckerTable {
  width: 100%;
}

.liveCountViewmodeSelect {
  width: 250px;
}

.leftMargin25 {
  margin-left: 25px;
}

.select-dropdown-std {
  width: 250px;
}

.select-dropdown-wide {
  width: 350px;
}

.form-input-cp {
  width: 250px;
}

// ------------------------------------ Image Segmentation testing

.segmentation-div{
  width: 99vh;
  background: white url('./assets/svgs/Eclipse-1s-200px.svg') center no-repeat;
}

.segmentation-canvas-div {
  position:relative;
  padding: 0;
}

div#segmentation-controls {
  button {
    margin: 0.5em 0.5em 0 0;
    border: 1px solid #999;
    background-color: #bbb;
    &:hover {
      background-color: #eee;
      border-color: #ccc;
    }

    &:disabled {
      color: #ccc;
      border-color: #ccc;
      background-color: #ddd;
    }
  }
}

.container {
  //width: 960px;
  margin: 0 auto;
  position: relative;
}

.draw-image {
  width: 100%;
  /* border: 1px solid #ccc; */
}

.draw-canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* background-color: #f0f; */
  /* opacity: 50%; */
}

.img-canvas {
  z-index: 1;
  opacity: 50%;
}

.top-canvas {
  z-index: 2;
  opacity: 50%;
}

.hidden {
  visibility: hidden;
}

.d-none {
  display: none;
}

div.button {
  display: inline-block;
  padding: 0.5em;
  border: 1px solid #ccc;
  cursor: pointer;
  margin-right: 1em;
}

div.button.disabled {
  color: #aaa;
  border-color: #ddd;
}

div.button.selected {
  background-color: #ddd;
  border-color: #aaa;
}

div.color-pick {
  display: inline-block;
  width: 1em;
  height: 1em;
  border: 1px solid #777;
  /* margin-top: 0.5em; */
  margin-left: 0.5em;
}

div.brush-preview{
  display: inline-block;
  background-color: black;
  border-radius: 50%;
  vertical-align: top;
}

.selectLabel {
  color: black;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 85%;
  border: 1px solid grey;
  padding-top: 10px;
  padding-bottom: 10px;
}

.selectLabel:hover {
  background-color: darkgrey !important;
  border-color: black;
}

.labelInexistent {
  color: black;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  width: 9%;
  border: 1px solid grey;
  padding-top: 4px;
  padding-bottom: 5px;
  font-size: 20px;
  font-weight: bolder;
}

.labelInexistent:hover {
  color: white;
  background-color: rgba(255, 0, 0, 0.4) !important;
  border-color: white;
}

.imageText {
  position: absolute;
  font-size: 25px;
  color: white;
  padding-left: 10px;
  z-index: 100;
}

.labelMissing {
  position: fixed;
  width: 33%;
  height: 40px;
  right: 27px;
  bottom: 125px;
  font-size: 18px;
  background-color: #0b4d75;
}

.labelMissing:hover {
  background-color: #0a3544;
}

.maskImage {
  position: absolute;
  z-index: 1;
  opacity: 0.5;
  background: none;

}

.labelsContainerWindowSegmentation {
  display: inline-block;
}

.labelDiv {
  text-align: center;
  border: 1px solid black;
  margin: 5px;
  padding: 3px;
  border-radius: 5px;
  background: white;
}

.colorLegend {
  width: 20px;
  height: 20px;
  display: inline-block;
  float: left;
  margin-left:5px;
}

.buttonColorLabelling {
  background-color: #6EB882;
}

.buttonColorSegmentation {
  background-color: #4B77A5;
}

.batchListHeader {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: stretch;
  width: 100%;

  font-size: 0.9em;
  padding-bottom: 5px;
  border-bottom: 1px solid;
  border-color: rgba(0, 0, 0, 0.4);
}

.batchListBody {
  display: inline-flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: stretch;
  width: 100%;
  font-size: 1em;
  height: 27em;
  //height: inherit;

  overflow: hidden;
  overflow-y: scroll;
}

.batchListItem {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: stretch;
  width: 100%;

  padding: 5px 0;
  border-bottom: 1px solid;
  border-color: rgba(0, 0, 0, 0.2);

  p {
    margin: 0px;
  }
}

.listBatchButton {
  border-radius: 50px;
  float: right;
  border: none;

  padding: 2px 0;
  font-size: 0.9em;

  background-color: #F0B250;
  color: white;

  p {
    display:inline;
  }
}

.paddingTop3 {
  padding-top: 3px;
}

.changeRoleDiv {
  margin: 55px 0 0px 45px;
}

.profileDropdown {
  width: 250px;
  display: inline-block;
}

.borderLabelling {
  border: 4px solid #6EB882;
}

.borderSegmentation {
  border: 4px solid #4B77A5;
}

.textColorLabelling {
  color: #6EB882;
}

.textColorSegmentation {
  color: #4B77A5;
}

.userInfoBoxContainer {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: stretch;
  width: 100%;
}

.userInfoBox {
  width: 100%;
  background-color: white;
  padding: 10px;
  margin: 45px;
  border-top: 25px solid;

  box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.05);
  border-radius: 3px;
}

.contentBoxFlex {
  display: inline-flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: stretch;
  width: 100%;
}

.contentBoxFlexElement {
  display:flex;
  width: 100%;
  padding: 10px 25px;
  justify-content: space-between;
  color: #496063;
}

.stdBox {
  background-color: white;
  width: calc(100% - 30px);
  margin-top: 25px;
  padding: 15px;
  box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.05);
  border-radius: 5px;
  color: #496063;
}

.feedbackHeader {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
}

.feedbackFunctionBoxesSection {
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: stretch;
}

.feedbackLabelBoxesContainer {
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  align-content: stretch;
  justify-content: space-between;
}

.feedbackLabelBoxes {
  width: 33%;

}

.agentFeedbackHeaderElement {
  display: inline-block;
  float: right;
  margin: 0 15px;
}

.justifyContent {
  justify-content: space-evenly;
}

.labelElement {
  white-space: pre-wrap;
  list-style-type: circle;
  display: list-item;
  margin-left: -27px;
  padding-left: 2px;
}

.feedbackImage {
  width: 50%;
  height: 50%;
  padding: 2px;
}

.feedbackError {
  color: #b10606;
}

.labelsContainerSelect {
  display:inline-block;
  width: 57%;
  padding-right: 25px;
}

.colorBox {
  display: inline-block;
  padding: 5px;
  margin-right: 5px;
}

.avoidableInput {
  width: 20%;
  display: inline-block;
}

.deviceVersionSelect {
  width: 250px;
}

.deviceVersionCommits {
  display: inline-block;
  width: 275px;
}

.deviceUpdateSWVersionSelect {
  width: 250px;
}

.deviceUpdateDeviceSelect {
  width: 500px;
}

.labelCheckerTableActive {
  height: calc( 73vh - 125px );
  width: 100%;
  overflow-y: scroll;
  overflow-x: scroll;
  margin-bottom: 10px;
}

.labelCheckerTableInactive {
  height: calc( 25vh - 125px );
  width: 100%;
  overflow-y: scroll;
  overflow-x: scroll;
}

.report-cards-container {
  display: flex;
  min-height: max-content;
  flex-wrap: wrap;
}

.report-card {
  width: calc(100% - 1em);
  @media (min-width: 600px) and (max-width: 900px) {
    width: calc(50% - 1em);
  }
  @media (min-width: 900px) and (max-width: 1200px) {
    width: calc(33.33333% - 1em);
  }
  @media (min-width: 1200px) and (max-width: 1500px) {
    width: calc(25% - 1em);
  }
  @media (min-width: 1500px) and (max-width: 1800px) {
    width: calc(20% - 1em);
  }
  @media (min-width: 1800px) {
    width: calc(16.66666% - 1em);
  }
  min-width: 150px;
  overflow-x: hidden;
  margin: 0.5em;
  border: 1px solid #ccc;
  border-radius: 0.5em;
  p:last-child {
    margin-bottom: 0;
  }
  .card-header {
    background-color: $color-primary;
    width: 100%;
    margin: 0;
    padding: 1em;
    border: none;
    border-radius: 0em;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }
  .card-header-disabled {
    @extend .card-header;
    background-color: #9d9d9d;
  }
  .card-content {
    span {
      font-weight: bold;
      padding-left: 20px;
      vertical-align: bottom;
    }
    width: 100%;
    margin: 0;
    padding: 1em;
    border: none;
    border-radius: 0em;
  }
}

.__react_component_tooltip {
  .headline {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 800;
    line-height: 1;
    padding-right: 20px;
  }

  .subheadline {
    margin-bottom: 2px;
    font-size: 13px;
    font-weight: 800;
    line-height: 1;
  }

  .text {
    margin-bottom: 10px;
    line-height: 18px;
    font-weight: 300;
    font-size: 11px;
  }

  .text-big {
    margin-bottom: 10px;
    line-height: 18px;
    font-weight: 300;
    font-size: 16px;

    b {
      font-weight: bold;
    }
  }
}

.form-control{
  // border-top-left-radius: 0;
  // border-bottom-left-radius: 0;
  // border-top-right-radius: 5;
  // border-bottom-right-radius: 5;
  height: 40px;
  max-width: 250px;
}

.form-control:focus {
  border-width: 1px;
  background-color: #fff;
  border-color: #2684FF;
  box-shadow: 0 0 0 1px #2684FF;

}

.input-group-prepend {
  height: 40px;
}
input[type="date"] {
  border-color: hsl(0,0%,80%);

}
input[type="date"]:hover {
  border-color: hsl(0,0%,72%);

}

input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  margin-right: 2px;
  opacity: 0.6;
  filter: invert(0.8);
  transition: 0.15s;
}

input[type="date"]::-webkit-calendar-picker-indicator:hover {
  cursor: pointer;
  margin-right: 2px;
  opacity: 0.6;
  filter: invert(0.1);
  transition: 0.2s;
}

.basic-multi-select liveCountViewmodeSelect select-form css-2b097c-container{
  border-radius: 0px !important;

}


.css-yk16xz-control:active{
  border-radius: 0px !important;
}

.input-group-text{
  border-radius: 0px !important;

}

.form-control{
  border-radius: 0px !important;

}
.css-1pahdxg-control{
  border-color: #2684FF !important;
  min-height: 40px !important;
  box-shadow: 0 0 0 1px #2684FF !important;
  border-radius: 0px !important;
}
.css-yk16xz-control:focus{
  border-radius: 0px !important;
}
.css-yk16xz-control:active{
  border-radius: 0px !important;
}
.css-yk16xz-control:focus{
  border-radius: 0px !important;
}
.css-yk16xz-control{
  border-radius: 0px !important;
  min-height: 40px !important;
}
.select__value-container:focus{
  border-radius: 0px !important;

}
