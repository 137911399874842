div.live-count-container {
  display: inline-block;
  padding: 0.25em;
  margin-left: 0.5em;
  border: 2px solid #555;
  border-radius: 0.25em;
}

div.live-count-total {
  border-color: #6EB882;
}
